#body_container {
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: center;
}
.prompt_column {
    margin-left: 20px;
    margin-right: 20px;
    max-width: 22%;
    text-align: center;
}
.prompt_title {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.493);
}
.discussion_container {
    /* max-width: 100%; */
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    transition: background-color 0.2s ease-in-out;
}
.discussion_container:hover{
    background-color: rgba(0, 0, 0, 0.25);
}
.discussion_container_split {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.watch_button_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.watch_live_button{
    background-color: red;
    color: black;
    border-radius: 10px;
    border: 0;
    padding: 5px;
    width: 33%;
    transition: background-color 0.2s ease-in-out;
}
.watch_live_button:hover{
    background-color: rgb(202, 0, 0);
}