body {
    font-family: 'Roboto', sans-serif;
}

.main-content {
    margin-top: 100px;
    padding: 20px;
    height: calc(100vh - 100px);
    overflow-y: auto;
}

#screen {
    margin: 0;
    padding: 0;
}