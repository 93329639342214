#videos_container {
    display: flex;
    flex-direction: row;
    height: 50%;
    margin-left: 30px;
    margin-right: 30px;
}

#videos_container div.video {
    flex:1;
    border-radius: 3px;
    margin: 2rem;
    height: 100%;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

#videos_container div video {
    background-color: rgb(0,0,0,0.20);
    width: 100%;
    /* height: 100%; */
    z-index: 1;
    position: relative;
}

.video_caption {
    position: absolute;
    z-index: 2;
    top: 1rem;
    left: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding: 0.25rem;
    background-color: #eee;
    color: #333;
    font-size: 1.5rem;
    border-radius: 2rem;
    font-size: small;
}