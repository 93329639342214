#body_container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

#side_nav {
  height: 100vh;
  width: 17vw;
  background-color: rgba(0, 0, 0, 0.15);
}

#top_section {
  height: 45%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#top_section div {
  height: 100%;
  width: 100%;
  padding: 30px;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.25);
  margin: 10px;
  transition: background-color 0.4s ease-in-out;
  border-radius: 8px;
}

#top_section div:hover {
  background-color: rgba(0, 0, 0, 0.25);
}

#main_text {
  color:rgb(121, 37, 199);
  font-size:3.5em;
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  text-align: center;
  align-items: center;
}

#featured_discussion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#featured_container {
  margin-top: 30px;
  height: 33%;
}

.agree_oppose_buttons {
  display: flex;
  flex-direction: row;
}

#main_body {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 30px;
  width: 100%;
  top: 0px;
  bottom: 0px;
}

#topics_list {
  height: 100%;
}

.side_list {
    display: flex;
    flex-direction: row;
}

.side_list::-webkit-scrollbar {
    display: none;
}

.side_list_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* min-width: 400px; */
    width: 33%;
    height: 90%;
    background-color: rgb(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 10px;
    margin: 20px;
    text-align: center;
    transition: background-color 0.4s ease-in-out;
}

.side_list_item:hover {
  background-color: rgb(0, 0, 0, 0.25);
}

.new_topic div {
    display: flex;
    flex-direction: row;
}

.partipants_label {
    color:rgb(95, 95, 95);
    font-style: italic;
}

.purple_button {
    background-color: rgb(121, 37, 199);
    border-radius: 8px;
    border-width: 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    margin: 10px;
}
.join_and_input {
    display: flex;
    flex-direction: row;
}
.join_and_input div {
    width: max-content;
    margin: 20px;
}

.promptName {
  font-size: x-large;
}