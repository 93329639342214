.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
.content {
    text-align: center;
}

.buttons {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.buttons button {
    margin: 10px;
}

.background {
    position: absolute;
    top: 120px;
    left: 0;
    width: 90%;
    height: 50%;
    display: flex;
    filter: blur(5px);
    margin-left: 5%;
    margin-right: 5%;
}

.video {
    flex: 1;
    background-color: #333;
    margin: 2rem;
}