#heading_container {
    position: relative;
}

#heading, #search_container {
    position: fixed;
    top: 0px;
    /* left: 0px; */
    width: 100%;
    height: 100px;
}

#heading {
    padding: 10px;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.25);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    z-index: 0;
}

#logos {
    display: flex;
    flex-direction: row;
}

#heading img {
    padding: 10px;
}

#search_container {
    left: 25vw;
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

#search_container a {
    text-decoration: none;
    color: inherit;
}

.search_bar {
    color: rgba(0, 0, 0, 0.747);
    background-color: rgba(0, 0, 0, 0.15);
    height: 3em;
    width: 25vw;
    border-radius: 3em;
    /* padding: 1em; */
    padding-left: 1.4rem;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease-in-out;
}

.search_bar:hover {
    background-color: rgba(0, 0, 0, 0.35);
}


#profile_link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px;
    padding: 20px;
}

#username {
    padding-right: 10px;
    font-size: large;
    color: black;
    text-decoration:none !important;
}