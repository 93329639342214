#login_main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#login_sub {
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin: 20px;
}

#login_sub h1 {
    padding: 40px;
}

#register_main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#register_sub {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.failed {
    color: red;
}